import { useState, SyntheticEvent, useEffect, useRef } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TrashLayout = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { resourceAllocate } = useResource();
  const redirectionLinks: string[] = [];
  if(resourceAllocate("leads-trash")){
    redirectionLinks.push("candidate-trash");
  }
  if(resourceAllocate("blogs-trash")){
    redirectionLinks.push("blog-trash");
  }


  useEffect(() => {
    const index = redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(redirectionLinks[newValue]);
    if (inputRef && inputRef?.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {resourceAllocate("leads-trash") && <Tab className="tabs-space start-tab-space" label="Candidates" {...a11yProps(0)} />}
          {resourceAllocate("blogs-trash") && <Tab className="tabs-space" label="Blogs" {...a11yProps(1)} />}
        </Tabs>
      </Box>
      <Outlet context={{ inputRef }} />
    </Box>
  );
};

export default TrashLayout;