import { Typography, useTheme } from "@mui/material";
import React, { FC } from "react";

interface props {
    name: string;
    count: number;
    select: string;
    onClick: (name: string) => void;
    className?: string
}

const Tag: FC<props> = ({ className, name, count, select, onClick }) => {
    const theme = useTheme();
    return(
<div
        className={`count-box ${className}`}
        style={{
            backgroundColor:
                select === name ? theme.palette.primary.light : "",
        }}
        onClick={() =>
            onClick(name)
        }
    >
        <Typography className="cards-title" variant="body1">{name}</Typography>
        <Typography className="analytics" variant="h4">
            {count || 0}
        </Typography>
    </div>
);};

export default Tag;