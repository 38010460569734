import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Drawer, IconButton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { NotificationService } from "../../services";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import useUser from "../../hooks/useUser";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { ICandidate } from "../../interfaces";
import useSnackbar from "../../hooks/useSnackbar";
import { capitalize } from "../../utilities/helper";
import { useNavigate } from "react-router-dom";
import "./style.scss";
dayjs.extend(calendar);

interface props {
    isOpen: boolean;
    count: number;
    reFetchCount: () => void;
    onClose: () => void;
}

const Notification: FC<props> = ({ isOpen, count, reFetchCount, onClose }) => {
    const { snackbar } = useSnackbar();
    const { user } = useUser();
    const navigate = useNavigate();
    const { getNotifications, updateNotification } = NotificationService();

    const notifications = useQuery({
        queryKey: ["notifications"],
        queryFn: () => getNotifications({ pagination: true, page: 1, limit: 50 })
    });

    const onReadNotification = async (_id: string) => {
        try {
            const update = await updateNotification({ notificationId: _id, updateAll: false });
            snackbar(update.message, "info");
            notifications.refetch();
            reFetchCount();
        } catch (error) {
            console.log("Error in notification ", error);
        }
    };

    const onReadAllNotifications = async () => {
        try {
            const update = await updateNotification({ updateAll: true });
            snackbar(update.message, "info");
            notifications.refetch();
            reFetchCount();
            onClose();
        } catch (error) {
            console.log("Error in notification ", error);
        }
    };

    const checkIsRead = (notify: { _id: string, isRead: boolean }[]) => {
        let isRead = false;
        notify?.map((ele) => {
            if (ele._id === user?._id) {
                isRead = ele.isRead;
            }
        });
        return isRead;
    };

    const navigateToCandidate = (candidate: ICandidate) => {
        const nav = `/candidates/${candidate.typeOfLead?.toLowerCase()}/manage/${candidate._id}`;
        navigate(nav);
        onClose();
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Box className="drawer-box">
                <Box className="center sticky-header">
                    <Typography variant="subtitle1">All Notifications</Typography>
                    <Box className="center">
                        {count ? (
                            <div onClick={onReadAllNotifications}>
                                <Typography
                                    className="mark-all-read"
                                    variant="subtitle2"
                                >
                                    Mark all as read
                                </Typography>
                            </div>
                        ) : null}
                        <IconButton className="icon-button" onClick={onClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {
                    notifications.data?.data?.map((notification, index) => (
                        <Accordion
                            key={index}
                            className={checkIsRead(notification?.notifyTo) ? "accordion accordion-read" : "accordion accordion-unread"}
                            onClick={() => checkIsRead(notification?.notifyTo) ? undefined : onReadNotification(notification._id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Avatar className="avatar">{user?.firstName && user?.firstName[0]?.toUpperCase()}</Avatar>
                                <Box className="content">
                                    <div className="message-preview">
                                        <Typography variant="body1">
                                            {notification.message.length > 30 ? notification.message.slice(0, 30) + "..." : notification.message}
                                        </Typography>
                                    </div>
                                    <Typography className="timestamp" variant="caption">
                                        {dayjs(dayjs(notification.createdAt)).calendar()}
                                    </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}>
                            {notification.category !== "ASSESSMENT" && notification.message}
                            {notification.category === "ASSESSMENT" && 
                                <div>
                                <p>
                                    {notification.message.split("View report:")[0]}
                                </p>
                                <p>
                                    View report:{" "}
                                    <a 
                                        href={notification.message.split("View report:")[1].trim()}
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        style={{ color: "var(--primary-color)", cursor: "pointer", textDecoration: "underline" }}
                                    >
                                        Click here
                                    </a>
                                </p>
                            </div>
                            }
                            {notification.category !== "ASSESSMENT" && notification.category !== "BLOG" && notification.category !== "RESOURCE_REQUEST" && <div className="mt-2">
                                <Typography variant="body1">
                                    Name: <span onClick={() => navigateToCandidate(notification._lead)} className="link-none-underline primary-color fw-bold">{capitalize(notification._lead?.name)}</span>
                                </Typography>
                                <Typography variant="body1">
                                    Email: {notification._lead?.email}
                                </Typography>
                            </div>}
                        </AccordionDetails>
                    </Accordion>
                    ))
                }
            </Box>
        </Drawer>
    );
    
};

export default Notification;