import React, { ChangeEvent, SyntheticEvent, useEffect } from "react";
import { Autocomplete, Box, Button, Grid, InputLabel, Link, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomLabel from "../../../../components/mui/custom-label";
import { IOrganisationConfigurationData, IOrganisationConfigurationTheme, IOrganisationConfigurationThemeField } from "../../../../interfaces/organisationConfiguration/theme";
import { organisationConfigurationDataService } from "../../../../services/organisation-themes";
import { IErrorResponse } from "../../../../interfaces";
import { fonts } from "../../../../utilities/helper";
import HttpService from "../../../../services/http";
import { joiResolver } from "@hookform/resolvers/joi";
import { organisationThemeValidation } from "../../../../validations/organisation";
import { useSelector } from "react-redux";

interface outletProps {
  refetchOrganisationConfiguration: () => void;
  reFetch: () => void,
}
const ManageConfigurationThemes = () => {  
  const { httpFormRequest } = HttpService();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { updateOrganisationThemeConfiguration } = organisationConfigurationDataService();

  const organisationConfigurationRedux = useSelector(
    (state: { themeConfiguration: { list: IOrganisationConfigurationData } }) =>
      state.themeConfiguration.list
  );  
  const orgConfigurationData = organisationConfigurationRedux && organisationConfigurationRedux.platForm?.adminPanel;

  const { handleSubmit, control, setValue, formState: { errors }, getValues } = useForm<IOrganisationConfigurationTheme>({
  resolver: joiResolver(organisationThemeValidation),
  defaultValues: {
    primaryColor: "",
    secondaryColor: "",
    primaryBackgroundColor: "",
    secondaryBackgroundColor: "",
    fontFamily: "",
    favicon: "",
    logo: "",
    metaTitle: "",
    loginPageLabel: "",
    bannerImage: "",
  }
});

  useEffect(() => {
    if (orgConfigurationData) {
      setValue("primaryColor",orgConfigurationData.primaryColor || "");
      setValue("secondaryColor",orgConfigurationData.secondaryColor || "");
      setValue("primaryBackgroundColor",orgConfigurationData.primaryBackgroundColor || "");
      setValue("secondaryBackgroundColor",orgConfigurationData.secondaryBackgroundColor || "");
      setValue("fontFamily",orgConfigurationData.fontFamily || "");
      setValue("favicon",orgConfigurationData.favicon || "");
      setValue("logo",orgConfigurationData.logo || "");
      setValue("metaTitle",orgConfigurationData.metaTitle || "");
      setValue("loginPageLabel",orgConfigurationData.loginPageLabel || "Welcome to ATS");
      setValue("bannerImage",orgConfigurationData.bannerImage || "");
    }
  }, [orgConfigurationData]);

  const onSubmit = async (data: IOrganisationConfigurationTheme) => {
    try {
      const update = await updateOrganisationThemeConfiguration(data);
      snackbar(update.message, "info");
      navigate("/organisation-configuration/theme");
      outlet?.refetchOrganisationConfiguration && outlet.refetchOrganisationConfiguration();

    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const fields: IOrganisationConfigurationThemeField[] = [
    { label: "Primary Color", name: "primaryColor", type: "colorPicker", required: true },
    { label: "Secondary Color", name: "secondaryColor", type: "colorPicker", required: true },
    { label: "Primary Background Color", name: "primaryBackgroundColor", type: "colorPicker", required: true },
    { label: "Secondary Background Color", name: "secondaryBackgroundColor", type: "colorPicker" , required: true},
    {
      label: "Font Style", name: "fontFamily", type: "auto-complete",
      options: fonts.map(font => ({ key: font, value: font })),
      displayFieldKey: "key",
      storeFieldKey: "value", 
      required: true
    },
    { label: "Title", name: "metaTitle", type: "input", required: true },
    { label: "Login Page Label", name: "loginPageLabel", type: "input", required: true },
    { label: "Logo", name: "logo", type: "input", required: true },
    { label: "Fav Icon", name: "favicon", type: "input", required: true },
    { label: "Banner Image", name: "bannerImage", type: "input", required: true },
  ];

  const DocumentFields: IOrganisationConfigurationThemeField[] = [
    {
      label: "Logo",
      name: "logo",
      type: "file",
    },
    {
      label: "Fav Icon",
      name: "favicon",
      type: "file",
    },
    {
      label: "Banner Image",
      name: "bannerImage",
      type: "file",
    }
  ];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const onChangeFontsAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: string | null,
  ) => {
    if(value){
      setValue("fontFamily", value);
    }
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files ? e.target.files[0].name : "",
          type === "favicon" ? ["ico"] : ["svg","ico","webp", "pdf", "png", "jpeg", "jpg"],
          10
      );
      const name = type === "logo" ? "logo" : type === "favicon" ? "favicon" : "bannerImage";
      setValue(name, uploaded.data.split("uploads")[1]);

    } catch (error) {
      snackbar((error as IErrorResponse).data.message, "error");
    }

  };

  const handleTextInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = event.target as HTMLInputElement;
    const maxLength = 30;
    const isLengthValid = inputValue.value.length < maxLength;
    const isEnterKey = event.key === "Enter";
    const isSpaceKey = event.key === " ";

    const isBackspaceKey = event.key === "Backspace";

    if (
      isEnterKey || 
      (isSpaceKey && inputValue.value.length === 0) ||
      (!isLengthValid && !isBackspaceKey)
    ) {
      event.preventDefault();
    }
  };
                   
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={handleKeyDown}
      mt={4}
    >
      <Grid container spacing={4}>
        {fields.map((field) => {
          if (field.type === "input") {
            return (
              <Grid key={field.label} item xs={4} md={6}>
                <Controller
                  control={control}
                  name={field.name}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field.required}
                        />
                      }
                      variant="outlined"
                      size="small"
                      disabled={!!(field.name === "favicon" || field.name === "logo" || field.name === "bannerImage")}
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      onChange={onChange}
                      value={value}
                      onKeyDown={handleTextInput}                                
                    />
                  )}
                />
              </Grid>
            );
          } else if (field.type === "colorPicker") {
            return (
              <Grid key={field.label} item xs={4} md={6}>
                <Controller
                  control={control}
                  name={field.name}
                  render={({ field: { onChange, value } }) => (
                    <Box className="color-picker-wrapper">
                      <TextField
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field.required}
                          /> 
                        }
                        value={value}
                        variant="outlined"
                        size="small"
                        disabled
                      />
                      <Box className="color-input">
                        <TextField
                          type="color"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          variant="standard"
                          size="small"
                        />
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            );
          }
          else if (field.type === "auto-complete") {
            return (
              <Grid key={field.label} item xs={4} md={6}>
                <Controller
                  control={control}
                  name={field.name}
                  render={(prop) => (
                    <>
                      <Autocomplete
                        fullWidth
                        options={fonts}
                        getOptionLabel={(option) => (option)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            placeholder={field.placeholder}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message}
                          />
                        )}
                        {...prop.field}
                        value={getValues(field.name)}
                        onChange={(e, value) =>
                          onChangeFontsAutoComplete(e, value)
                        }
                      />
                    </>
                  )}
                />
              </Grid>
            );
          }
        })}
        {DocumentFields.map((field) => {
          const hasValue = getValues(field.name);
          const shouldShowLabel = hasValue;

          return (
            <Grid key={field.label} item xs={12} md={6} mb={2}>
              <div>
                <div
                  style={{ color: "rgb(118, 118, 118)", marginBottom: "3px" }}
                >
                  {shouldShowLabel && <label>{field.label}</label>}
                </div>
                <Grid container spacing={2}>
                  {hasValue && (
                    <Grid item xs>
                      <Link
                        href={
                          String(process.env.REACT_APP_S3_BASE_URL) +
                          getValues(field.name)?.toString()
                        }
                        target="_blank"
                        underline="none"
                        color="inherit"
                      >
                        <Button variant="outlined" fullWidth>
                          Preview
                        </Button>
                      </Link>
                    </Grid>
                  )}
                  {!hasValue && (
                    <Grid item xs>
                      <InputLabel id={`upload-${field.name}`}>
                        <Button component="label" fullWidth>
                          Upload {field.label}*
                          <input
                            hidden
                            type="file"
                            id={`upload-${field.name}`}
                            onChange={(e) => uploadFile(e, field.name)}
                            accept="application/pdf, image/png, image/jpeg, .doc, .docx, .ico .webp"
                          />
                        </Button>
                      </InputLabel>
                    </Grid>
                  )}
                  {hasValue && (
                    <Grid item xs>
                      <InputLabel id={`upload-${field.name}`}>
                        <Button component="label" fullWidth variant="outlined">
                          Change {field.label}
                          <input
                            hidden
                            type="file"
                            id={`upload-${field.name}`}
                            onChange={(e) => uploadFile(e, field.name)}
                            accept="application/pdf, image/png, image/jpeg, .doc, .docx, .ico"
                          />
                        </Button>
                      </InputLabel>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </Box>
  );
};

export default ManageConfigurationThemes;
