import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { MenuItem } from "../../../../components/shared/filter";
import { ITrashFilter, IJob } from "../../../../interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { capitalize, checkTimePeriod } from "../../../../utilities/helper";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LPAMarks, dayMarks } from "../../../candidate-layout/common/helper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton,
    ListItemText, Menu, Typography, Slider, Switch
} from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const TrashFilter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [...jobs] = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];
    const [state, setState] = useState<ITrashFilter>({
        selectedMenu: 0,
        ctc: {
            isApply: false,
            value: [1, 5]
        },
        ectc: {
            isApply: false,
            value: [1, 5]
        },
        noticePeriod: {
            isApply: false,
            value: [15, 30]
        },
        jobId: [],
        date: []
    });

    useEffect(() => {
        const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
        const ctc: { isApply: boolean, value: number[] } = searchParams.get("ctc") ? JSON.parse(String(searchParams.get("ctc"))) : { isApply: false, value: [1, 5] };
        const ectc: { isApply: boolean, value: number[] } = searchParams.get("ectc") ? JSON.parse(String(searchParams.get("ectc"))) : { isApply: false, value: [1, 5] };
        const noticePeriod: { isApply: boolean, value: number[] } = searchParams.get("notice-period") ? JSON.parse(String(searchParams.get("notice-period"))) : { isApply: false, value: [15, 30] };
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        setState(prevState => ({
            ...prevState,
            jobId,
            ctc,
            ectc,
            noticePeriod,
            date
        }));
    }, [searchParams]);

    const handleDateListItem = (name: "date", key: string, value: string) => {
        const date = checkTimePeriod(key);

        const payload: Array<{
            key: string;
            value: string;
            startDate: string,
            endDate: string,
        }> = [{
            key,
            value,
            startDate: date.startDate,
            endDate: date.endDate,
        }];

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let date: Array<{
            key: string;
            value: string;
            startDate: string,
            endDate: string,
        }> = [];

        if (state.date.length && period === "start") {
            date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.date.length && period === "end") {
            date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.date.length && period === "start") {
            date = [{
                key: "custom",
                value: "custom",
                startDate: newDoj,
                endDate: new Date().toISOString()
            }];
        } else {
            date = [{
                key: "custom",
                value: "custom",
                startDate: new Date().toISOString(),
                endDate: newDoj,
            }];
        }

        setState(prevState => ({
            ...prevState,
            date
        }));
    };

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };

    const handleRightListItem = (name: "jobId", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "jobId" | "date", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteSliderChip = (name: "ctc" | "ectc" | "noticePeriod") => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                isApply: false,
                value: name === "noticePeriod" ? [15, 30] : [1, 5]
            }
        }));
    };

    const handleSlider = (name: "ctc" | "ectc" | "noticePeriod") => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                isApply: !prevState[name].isApply
            }
        }));
    };

    const sliderRange = (event: Event, newValue: number | number[], name: "ctc" | "ectc" | "noticePeriod") => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: newValue as number[]
            }
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            jobId: [],
            ctc: {
                isApply: false,
                value: [1, 5]
            },
            ectc: {
                isApply: false,
                value: [1, 5]
            },
            noticePeriod: {
                isApply: false,
                value: [15, 30]
            },
            date: []
        });
    };

    const onApply = () => {
        searchParams.set("jobId", JSON.stringify(state.jobId));
        searchParams.set("date", JSON.stringify(state.date));

        if (state.ctc.isApply) {
            searchParams.set("ctc", JSON.stringify(state.ctc));
        } else {
            searchParams.delete("ctc");
        }

        if (state.ectc.isApply) {
            searchParams.set("ectc", JSON.stringify(state.ectc));
        } else {
            searchParams.delete("ectc");
        }

        if (state.noticePeriod.isApply) {
            searchParams.set("notice-period", JSON.stringify(state.noticePeriod));
        } else {
            searchParams.delete("notice-period");
        }

        searchParams.set("page", "1");
        navigate({
            pathname: "/trash/candidate-trash",
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
        const ctc: { isApply: boolean, value: number[] } = searchParams.get("ctc") ? JSON.parse(String(searchParams.get("ctc"))) : { isApply: false, value: [1, 5] };
        const ectc: { isApply: boolean, value: number[] } = searchParams.get("ectc") ? JSON.parse(String(searchParams.get("ectc"))) : { isApply: false, value: [1, 5] };
        const noticePeriod: { isApply: boolean, value: number[] } = searchParams.get("notice-period") ? JSON.parse(String(searchParams.get("notice-period"))) : { isApply: false, value: [15, 30] };
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        setState(prevState => ({
            ...prevState,
            jobId,
            ctc,
            ectc,
            noticePeriod,
            date
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.jobId.length || state.ctc.isApply || state.ectc.isApply || state.noticePeriod.isApply || state.date.length) ?
                                <>
                                    {state.jobId.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("jobId", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.ctc.isApply && <Chip className="m-1" color="primary" onDelete={() => deleteSliderChip("ctc")} label="CTC" />}
                                    {state.ectc.isApply && <Chip className="m-1" color="primary" onDelete={() => deleteSliderChip("ectc")} label="Expected CTC" />}
                                    {state.noticePeriod.isApply && <Chip className="m-1" color="primary" onDelete={() => deleteSliderChip("noticePeriod")} label="Notice Period" />}
                                    {state.date.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("date", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Job Title"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.jobId}
                            />
                            <MenuItem
                                index={1}
                                label="CTC"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                isApplied={state.ctc.isApply}
                                min={state.ctc.value[0]}
                                max={state.ctc.value[1]}
                            />
                            <MenuItem
                                index={2}
                                label="Expected CTC"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                isApplied={state.ectc.isApply}
                                min={state.ectc.value[0]}
                                max={state.ectc.value[1]}
                            />

                            <MenuItem
                                index={3}
                                label="Notice Period"
                                selectedMenu={state.selectedMenu === 3}
                                onChange={handleLeftListItem}
                                isApplied={state.noticePeriod.isApply}
                                min={state.noticePeriod.value[0]}
                                max={state.noticePeriod.value[1]}
                            />
                            <MenuItem
                                index={4}
                                label="Date"
                                selectedMenu={state.selectedMenu === 4}
                                onChange={handleLeftListItem}
                                count={state.date}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                jobs.map(jobTitle => <ListItemButton
                                    key={jobTitle._id}
                                    selected={!!state.jobId.find(ele => ele.key === jobTitle._id)}
                                    onClick={() => handleRightListItem("jobId", jobTitle._id, jobTitle.title)}
                                >
                                    <ListItemText primary={jobTitle.title} />
                                    <Checkbox edge="end" checked={!!state.jobId.find(ele => ele.key === jobTitle._id)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                <>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography className="fw-bold" variant="body1">CTC Range</Typography>
                                        <Switch onClick={() => handleSlider("ctc")} checked={state.ctc.isApply ? true : false} />
                                    </Box>
                                    <Typography variant="caption">Shown CTC are in LPA unit</Typography>
                                    <Box marginTop="30px" marginX="25px">
                                        <Slider
                                            disabled={state.ctc.isApply ? false : true}
                                            defaultValue={[1, 4]}
                                            min={1}
                                            max={20}
                                            marks={LPAMarks}
                                            value={state.ctc.value}
                                            onChange={(e, n) => sliderRange(e, n, "ctc")}
                                            track="inverted"
                                            valueLabelDisplay="auto"
                                            aria-labelledby="track-inverted-slider"
                                        />
                                    </Box>
                                </>
                            }
                            {
                                state.selectedMenu === 2 &&
                                <>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography className="fw-bold" variant="body1">Expected CTC Range</Typography>
                                        <Switch onClick={() => handleSlider("ectc")} checked={state.ectc.isApply ? true : false} />
                                    </Box>
                                    <Typography variant="caption">Shown Expected CTC are in LPA unit</Typography>
                                    <Box marginTop="30px" marginX="25px">
                                        <Slider
                                            disabled={state.ectc.isApply ? false : true}
                                            defaultValue={[1, 4]}
                                            min={1}
                                            max={20}
                                            marks={LPAMarks}
                                            value={state.ectc.value}
                                            onChange={(e, n) => sliderRange(e, n, "ectc")}
                                            track="inverted"
                                            valueLabelDisplay="auto"
                                            aria-labelledby="track-inverted-slider"
                                        />
                                    </Box>
                                </>
                            }
                            {
                                state.selectedMenu === 3 &&
                                <>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography className="fw-bold" variant="body1">Notice Period Range</Typography>
                                        <Switch onClick={() => handleSlider("noticePeriod")} checked={state.noticePeriod.isApply ? true : false} />
                                    </Box>
                                    <Typography variant="caption">Shown notice period are in days</Typography>
                                    <Box marginTop="30px" marginX="25px">
                                        <Slider
                                            disabled={state.noticePeriod.isApply ? false : true}
                                            defaultValue={[15, 30]}
                                            marks={dayMarks}
                                            value={state.noticePeriod.value}
                                            onChange={(e, n) => sliderRange(e, n, "noticePeriod")}
                                            track="inverted"
                                            valueLabelDisplay="auto"
                                            aria-labelledby="track-inverted-slider"
                                        />
                                    </Box>
                                </>
                            }
                            {
                                state.selectedMenu === 4 &&
                                <>
                                    {[
                                        { key: "thisWeek", value: "Weekly" },
                                        { key: "thisMonth", value: "Monthly" },
                                        { key: "thisQuarter", value: "Quarterly" },
                                    ]?.map((date) =>
                                        <ListItemButton
                                            key={date.key}
                                            selected={state.date.find(ele => ele.key === date.key) ? true : false}
                                            onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                                        >
                                            <ListItemText primary={date.value} />
                                            <Checkbox edge="end" checked={state.date.find(ele => ele.key === date.key) ? true : false} />
                                        </ListItemButton>
                                    )}
                                    <Box marginTop={2}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                value={dayjs(state.date[0]?.startDate)}
                                                onChange={e => handleDate(e, "start")}
                                                label="Start Date"
                                                format="LL"
                                            />
                                        </LocalizationProvider>
                                        <div className="mt-3" />
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                value={dayjs(state.date[0]?.endDate)}
                                                onChange={e => handleDate(e, "end")}
                                                label="End Date"
                                                format="LL"
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </>

                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default TrashFilter;