import "./style.scss";
import { Box, Button, IconButton, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams, useSearchParams, useOutletContext } from "react-router-dom";
import { SyntheticEvent, useEffect, useState, FC } from "react";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel, a11yProps } from "../../../../components/shared/mui-tabs";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "@tanstack/react-query";
import { CollegeService } from "../../../../services";
import ManageCollege from "./manage";
import Timeline from "./timeline";
import EditIcon from "@mui/icons-material/Edit";
import ContactPersonDetail from "./contact-person";
import AddIcon from "@mui/icons-material/Add";
import useResource from "../../../../hooks/useResource";


const redirectionLinks = ["college-detail", "contact-person", "timeline"];
interface outletProps {
    reFetch: () => void
}

interface SideButtonProps {
    index: number;
    setActiveAction: (e: boolean) => void;
}

const SideButton: FC<SideButtonProps> = ({ index, setActiveAction }) => {
    let label = "";

    if (index === 1) {
        label = "Add Contact Person";
    } else {
        return null;
    }

    return (
        <Button
            variant="text"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setActiveAction(true)}
        >
            {label}
        </Button>
    );
};

const CollegeLayout = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const outlet = useOutletContext<outletProps>();
    const [value, setValue] = useState(0);
    const { getCollege } = CollegeService();
    const { resourceAllocate } = useResource();
    const [isDisable, setIsDisable] = useState(true);
    const [searchParam, setSearchParam] = useSearchParams();
    const [activeAction, setActiveAction] = useState<boolean>(false);
    const college = useQuery({
        queryKey: ["colleges"],
        queryFn: () => getCollege({ _id: id })
    });

    useEffect(() => {
        const tab = searchParam.get("type") ? String(searchParam.get("type")) : "college-detail";
        setValue(redirectionLinks.indexOf(tab));
    }, [searchParam]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        searchParam.set("type", redirectionLinks[newValue]);
        setSearchParam(searchParam);
        setActiveAction(false);
    };

    const onClose = () => {
        searchParam.delete("type");
        navigate({
            pathname: "/cpd/college",
            search: searchParam.toString()
        });
    };

    return <div>
        <BootstrapDialog
            maxWidth="lg"
            onClose={onClose}
            open={id ? true : false}
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    Edit College{resourceAllocate("college.write") && <IconButton sx={{ visibility: (isDisable && value < 1) ? "visible" : "hidden" }} className="ml-2" color="primary" onClick={() => setIsDisable(false)}><EditIcon /></IconButton>}
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ height: "65vh" }}>
                <Box width="100%" height="100%" >
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Box>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab className="tabs-space start-tab-space" label="College Detail" {...a11yProps(0)} />
                                <Tab className="tabs-space" label="Contact Person" {...a11yProps(1)} />
                                <Tab className="tabs-space" label="Timeline" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        {!activeAction && (
                            <SideButton index={value} setActiveAction={setActiveAction} />
                        )}
                    </Box>

                    {/* College Detail */}
                    <TabPanel
                        style={{
                            height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
                            overflow: "auto",
                            marginTop: 10
                        }}
                        value={value}
                        index={0}>
                        <ManageCollege
                            college={college.data?.data}
                            isDisable={isDisable}
                            setIsDisable={setIsDisable}
                            onClose={onClose}
                        />
                    </TabPanel>

                    {/* Contact Person  */}
                    <TabPanel value={value} index={1}>
                        <ContactPersonDetail
                            college={college.data?.data}
                            currentTab={value}
                            activeAction={activeAction}
                            contactDetailRefetch={college.refetch}
                            contactListRefetch={outlet.reFetch} />
                    </TabPanel>

                    {/* TimeLine  */}
                    <TabPanel value={value} index={2}>
                        <Timeline
                            college={college.data?.data}
                        />
                    </TabPanel>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    </div>;
};

export default CollegeLayout;
