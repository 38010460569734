import "../style.scss";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, Typography, useTheme } from "@mui/material";
import { FC, useEffect } from "react";
import ErrorMessage from "../../shared/error-message";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import { defaults } from "chart.js";
Chart.register(...registerables, ArcElement, Tooltip, Legend);

interface props {
    label?: string;
    data: {
        labels: string[];
        // eslint-disable-next-line
        datasets: any;
    };
    height?: number;
    errorMessage?: string;
}

const DonutGraph: FC<props> = ({ label, data, height, errorMessage }) => {    
    const theme = useTheme();
  
    useEffect(() => {
      defaults.font.family = theme.typography.fontFamily;
    }, [theme]);

    return (

    <Box className="box-container" sx={{ height: height }}>
        {
            data.labels.length
                ?
                <>
                    <Typography variant="h6">
                        {label}
                    </Typography>
                    <div>
                        <Doughnut
                            data={data}
                            width={100}
                            height={Number(height) -40}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                            }}
                        />
                    </div>
                </>
                :
                <ErrorMessage Icon={<DonutSmallIcon fontSize="large" className="error-icon" />} fullHeight errorMessage={errorMessage} />
        }
    </Box>
    );
};

DonutGraph.defaultProps = {
    height: 400,
};

export { DonutGraph };