import Joi from "joi";
import { required } from "../shared";

export const organisationThemeValidation = Joi.object({
    primaryColor: Joi.string()
        .required()
        .label("Primary Color")
        .messages({
            "string.empty": required,
        }),
    secondaryColor: Joi.string()
        .required()
        .label("Secondary Color")
        .messages({
            "string.empty": required,
        }),
    primaryBackgroundColor: Joi.string()
        .required()
        .label("Primary Background Color")
        .messages({
            "string.empty": required,
        }),
    secondaryBackgroundColor: Joi.string()
        .required()
        .label("Secondary Background Color")
        .messages({
            "string.empty": required,
        }),
    fontFamily: Joi.string()
        .required()
        .label("Font Family")
        .messages({
            "string.empty": required,
        }),
    favicon: Joi.string()
        .required()
        .label("Favicon")
        .messages({
            "string.empty": required,
        }),
    bannerImage: Joi.string()
        .required()
        .label("Banner Image")
        .messages({
            "string.empty": required,
        }),
    loginPageLabel: Joi.string()
        .required()
        .label("Login Page Label")
        .messages({
            "string.empty": required,
        }),
    logo: Joi.string()
        .required()
        .label("Logo")
        .messages({
            "string.empty": required,
        }),
    metaTitle: Joi.string()
        .required()
        .label("Meta Title")
        .messages({
            "string.empty": required,
        }),
});