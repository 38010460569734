import { useEffect, useState, MouseEvent } from "react";
import { Badge, Box, Button, Grid, Tooltip, useTheme } from "@mui/material";
import { BarGraph, DonutGraph } from "../../../components/graphs";
import {
    useQuery
} from "@tanstack/react-query";
import ReportService from "../../../services/report";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSearchParams } from "react-router-dom";
import ReportFilters from "../components/filter";
import { IReportFilter, IReportFilterDialog } from "../../../interfaces";

const CpdAnalysis = () => {
    const theme = useTheme();
    const { cpdReport } = ReportService();
    const [searchParams] = useSearchParams();
    const [filters, setFilters] = useState<IReportFilter>({
        department: [],
        job: [],
        typeOfLead: [],
        assignTo: []
      });
    const [filtersCount, setFiltersCount] = useState(0);
    const getAllCount = useQuery({
        queryKey: ["report-overview", filters],
        queryFn: () => cpdReport({ ...filters })
    });   
    useEffect(() => {
        let filterCount = 0;
        const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
        const job: { key: string, value: string }[] = searchParams.get("job") ? JSON.parse(String(searchParams.get("job"))) : [];
        const department: { key: string, value: string }[] = searchParams.get("department") ? JSON.parse(String(searchParams.get("department"))) : [];
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
        let createdAt: {
            startDate: string;
            endDate: string;
        } | undefined = undefined;
        if (date?.length) {
            createdAt = {
                startDate: date[0]?.startDate,
                endDate: date[0]?.endDate,
            };
        }

        filterCount += typeOfLead.length ? 1 : 0;
        filterCount += job.length ? 1 : 0;
        filterCount += department.length ? 1 : 0;
        filterCount += assignTo.length ? 1 : 0;
        filterCount += date.length ? 1 : 0;
        setFiltersCount(filterCount);
        setFilters(prev => ({
            ...prev,
            job: job.map((item) => item.key),
            assignTo: assignTo.map((item) => item.key),
            department: department.map((item) => item.value),
            typeOfLead: typeOfLead.map((item) => item.key),
            date: createdAt
        }));
    }, [searchParams]);

    const [state, setState] = useState<IReportFilterDialog>({
        filterDialog: {
            anchorEl: null,
            isOpen: false
        }
    });

    const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
        const anchorElement = e.currentTarget;

        setState((prevState) => ({
            ...prevState,
            filterDialog: {
                ...prevState.filterDialog,
                anchorEl: anchorElement,
                isOpen: !state.filterDialog.isOpen
            }
        }));
    };

    const closeFilter = () => {
        setState((prevState) => ({
            ...prevState,
            filterDialog: {
                ...prevState.filterDialog,
                isOpen: false
            }
        }));
    };
    const showData = (label: string) => {
        const multiColor = ["rgba(153, 102, 255, 0.3)", "rgba(54, 162, 235, 0.3)", "rgba(255, 99, 132, 0.3)", "rgba(255, 206, 86, 0.3)", "rgba(75, 192, 192, 0.2)", "rgba(254, 234, 230, 0.9)", "rgba(98, 54, 114, 0.3)", "rgba(54, 114, 61, 0.3)"];
        const data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: theme.palette.primary.light
                },
            ],
        };

        const pipelineLabel: string[] = [];
        const pipelineData: number[] = [];
        getAllCount.data?.data?.pipeline?.forEach(cpd => {
            pipelineLabel.push(cpd._id);
            pipelineData.push(cpd.count);
        });

        const hiredLabel: string[] = [];
        const hiredData: number[] = [];
        getAllCount.data?.data?.statusBasedLeads?.forEach(cpd => {
            hiredLabel.push(cpd._id);
            hiredData.push(cpd.count);
        });

        const cpdLabels: string[] = [];
        const cpdCount: number[] = [];
        if (getAllCount.data?.data?.allCandidatesByDate) {
            Array.isArray(getAllCount.data?.data?.allCandidatesByDate) && getAllCount.data?.data?.allCandidatesByDate.forEach(cpd => {
                cpdLabels.push(cpd._id);
                cpdCount.push(cpd.count);
            });
        }
        if (label === "pipeline") {
            return {
                ...data,
                labels: pipelineLabel,
                datasets: [
                    {
                        label: "Count",
                        data: pipelineData,
                        backgroundColor: theme.palette.primary.light
                    },
                ],
            };
        } else if (label === "status") {
            return {
                ...data,
                labels: hiredLabel,
                datasets: [
                    {
                        label: "Count",
                        data: hiredData,
                        backgroundColor: multiColor,
                    },
                ],
            };
        } else if (label === "totalApplied") {
            return {
                ...data,
                labels: cpdLabels,
                datasets: [
                    {
                        label: "Count",
                        data: cpdCount,
                        backgroundColor: theme.palette.primary.light
                    },
                ],
            };
        } else {
            return data;
        }

    };

    return (
        <>
            <Box marginTop="10px" height="calc(100vh - 145px)">
                <Box className="tab-header" marginBottom="30px">
                    <Box className="tab-header-box">
                        <Box display="flex" alignItems="center" className="filter-box">
                            <Tooltip title='Filter'>
                                <Badge
                                    className="h-100 ml-2"
                                    color="primary"
                                    badgeContent={filtersCount}
                                    invisible={!filtersCount}
                                >
                                    <Button
                                        className={filtersCount ? "filter active" : "filter default"}
                                        variant='outlined'
                                        onClick={openFilter}
                                    >
                                        <FilterListIcon />
                                    </Button>
                                </Badge>
                            </Tooltip>
                        </Box>
                        <ReportFilters
                            anchorEl={state.filterDialog.anchorEl}
                            isOpen={state.filterDialog.isOpen}
                            OnClose={closeFilter}
                        />
                    </Box>
                </Box> 
                <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
                    {/* show graph  */}
                    <Box marginTop="16px">
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <DonutGraph
                                    label="On Campus Status"
                                    data={showData("status")}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <BarGraph
                                    label="On Campus Candidate Pipeline"
                                    data={showData("pipeline")}
                                    isStacked
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BarGraph
                                    label="On Campus Total Applied"
                                    data={showData("totalApplied")}
                                    height={390}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CpdAnalysis;