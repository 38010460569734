import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { FC } from "react";

interface props {
    label: string;
    count: number;
}

const CircularProgressWithLabel: FC<props> = ({ count, label }) => <>
    <CircularProgress color="primary" className="w-100 h-100" variant="determinate" value={100} />
    <Box
        sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <div>
            <Typography className="text-center fw-bold" color="text.secondary">
                {count}
            </Typography>
            <Typography color="text.secondary">
                {label}
            </Typography>
        </div>
    </Box>
</>;

export default CircularProgressWithLabel;