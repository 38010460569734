import "./style.scss";
import { useState, MouseEvent, useEffect } from "react";
import { Button, TextField, Typography, InputAdornment, IconButton, Box, Divider } from "@mui/material";
import { IAuth, IAuthResponse } from "../../../interfaces/auth";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { LoginValidation } from "../../../validations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthService from "../../../services/auth";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Logo from "../../../assets/images/logo.png";
import GoogleLogo from "../../../assets/images/google-logo.svg";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../interfaces";
import { organisationConfigurationDataService } from "../../../services/organisation-themes";
import { useQuery } from "@tanstack/react-query";

const Login = () => {
  const authService = AuthService();
  const navigate = useNavigate();
  const { user } = useUser();
  const { snackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<IAuth>({
    resolver: joiResolver(LoginValidation),
  });
  const { getOrganisationThemeConfiguration } = organisationConfigurationDataService();

  const organisationConfiguration = useQuery({
    queryKey: ["organisation-theme-configuration"],
    queryFn: () => getOrganisationThemeConfiguration(),
  });
  const orgThemeData = organisationConfiguration && organisationConfiguration.data && organisationConfiguration.data.data;
  const logo = orgThemeData && orgThemeData.logo && String(process.env.REACT_APP_S3_BASE_URL) + orgThemeData.logo || Logo;
  const fontFamily = orgThemeData && orgThemeData.fontFamily || "sans-serif";
  const loginPageLabel = orgThemeData && orgThemeData.loginPageLabel || "Welcome to ATS";

  useEffect(() => {
    if (orgThemeData) {
      const favicon = document.getElementById("favicon");
      if (favicon) {
        favicon.setAttribute("href", `${process.env.REACT_APP_S3_BASE_URL}${orgThemeData.favicon}`);
      }
      document.title = orgThemeData.metaTitle;
    }
  }, [orgThemeData]);

  const dynamicTheme = {
    "--primary-color": orgThemeData && orgThemeData.primaryColor || "#118CFC",
    "--font-family": orgThemeData && orgThemeData.fontFamily || "sans-serif",
  } as React.CSSProperties;

  useEffect(() => {
    if (user?.name?.length) {
      navigate("/");
    }
  }, [user]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onLogin = async (credentials: IAuth) => {
    try {
      const auth = await authService.login(credentials) as IAuthResponse;
      localStorage.setItem("currentUserToken", auth?.data?.token);
      snackbar(auth.message, "info");
      navigate("/dashboard");

    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message ?? "Please try again after sometime", "warning");
      console.log(error);
    }
  };

  const generateCode = async () => {
    try {
      const auth = await authService.generateGoogleToken() as { data: string };
      window.open(auth?.data, "_self");
    } catch (error) {
      const err = error as IErrorResponse;
      console.log("Error in google login", err);
    }
  };

  return (
    <div style={dynamicTheme}>
      <Box className="login-container center" flexDirection="column">
        <img alt="logo" src={logo} width="63" height="66" />
        <Typography className="margin-top-40 fw-bold welcome" variant="h5">{loginPageLabel}</Typography>
        <p className="sub-text grey-500">Helping you to manage and hire candidates</p>
        <Box className='credential-box' marginTop="40px" marginX="10px">
          <form onSubmit={handleSubmit(onLogin)}>
            <TextField
              placeholder="Email*"
              className="mb-3"
              {...register("email")}
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
                sx: {
                  fontFamily: fontFamily
                }
              }}
              InputLabelProps={{
                sx: { fontFamily }, 
              }}
              autoComplete="username"
            />
            <TextField
              placeholder="Password*"
              className="mb-3"
              type={showPassword ? "text" : "password"}
              {...register("password")}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  fontFamily: fontFamily
                }
              }}
              InputLabelProps={{
                sx: { fontFamily }, 
              }}
              autoComplete="current-password"
            />
            <div className='center'>
              <p className="margin-top-40 sub-text primary-color text-center pointer-cursor" onClick={() => navigate("/forgot-password")}>Forgot Password?</p>
            </div>
            <Button type="submit" className="margin-top-40 height-50 login-btn" fullWidth >Login</Button>
          </form>
          <Divider className="margin-top-40 ">
            <p className="sub-text grey-400">OR</p>
          </Divider>
          <Button className="margin-top-40 height-50 google" fullWidth onClick={generateCode}>
            <img alt="google" src={GoogleLogo} className="google-image"/>
            LOG IN WITH GOOGLE
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Login;