import "./style.scss";
import { ChangeEvent, FC, ReactNode } from "react";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ICandidate } from "../../../interfaces";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    backgroundColor: "#fff"
}
}));

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface props {
  data?: ICandidate[] | undefined;
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  title: string;
  isOpen: boolean;
  disabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  onClose: () => void;
  onConfirm?: () => void;
  onSubmit?: () => void;
  children: ReactNode;
  pagination?: {
    page: number;
    totalPages: number;
  };
  onPageChange?: (e: ChangeEvent<unknown>, page: number) => void;
}

const CategoryCustomDialog: FC<props> = ({
  data,
  size,
  title,
  isOpen,
  disabled,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
  onSubmit,
  children,
  pagination,
  onPageChange,
  onSearch
}) => (
  <div>
    <BootstrapDialog
      maxWidth={size}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      scroll="paper"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
        {onSearch && (
          <Box className="search-boxx">
            <SearchIcon className="search-icon" />
              <input
                className="search-input"
                name="search"
                placeholder="Search lead by name"
                onChange={onSearch}
              />
          </Box>
        )}
      </BootstrapDialogTitle>
      <form id="dialog-form" onSubmit={onSubmit}>
        <DialogContent>{children}</DialogContent>
        {pagination && data && data.length ? (
          <Box marginTop="10px" marginBottom="20px">
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={onPageChange}
              color="primary"
            />
          </Box>
        ) : null}
        {!disabled && (
          <DialogActions id="dialog-action">
            <Button variant="outlined" size="medium" onClick={onClose}>
              {cancelText ? cancelText : "Discard"}
            </Button>
            <Button
              size="medium"
              autoFocus
              onClick={() => onConfirm && onConfirm()}
            >
              {confirmText ? confirmText : "Save changes"}
            </Button>
          </DialogActions>
        )}
      </form>
    </BootstrapDialog>
  </div>
);

CategoryCustomDialog.defaultProps = {
  size: "md",
  disabled: false,
};

export default CategoryCustomDialog;