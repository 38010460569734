import "./style.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalize, formatMobileNumber, formatDate } from "../../utilities/helper";
import { ICandidate, ICandidateDashboardRows } from "../../interfaces";
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import Tag from "./components/tags";
import { BarGraph } from "../../components/graphs";
import CustomTable from "../../components/mui/table";
import AnalyticsService from "../../services/dashboard";
import { CandidateService } from "../../services";
import {
  useQuery
} from "@tanstack/react-query";
import CustomTypography from "../../components/mui/max-length-limit";

const columns = [
  {
    id: "id",
    label: "S No."
  },
  {
    id: "name",
    label: "Name"
  },
  {
    id: "email",
    label: "Email"
  },
  {
    id: "phone",
    label: "Phone"
  }
];

const createRow = (index: number, candidate: ICandidate) => (
  {
    id: index + 1,
    name: (
      <CustomTypography
        limit={30}
        label={capitalize(candidate.name)}
      />
    ),
    email: (
      <CustomTypography
        limit={20}
        label={candidate.email}
      />
    ),
    phone: formatMobileNumber(candidate.mobileNumber) || ""
  }
);

interface IState {
  selectedTag: string;
  period: string;
  analytics: {
    labels: string[];
    data: number[];
  }
}

const Dashboard = () => {
  let rows: Array<ICandidateDashboardRows> = [];
  const theme = useTheme();
  const { getAnalyticsCount, dayWiseAnalytics } = AnalyticsService();
  const { getCandidates } = CandidateService();
  const navigate = useNavigate();
  const getAllCount = useQuery({ queryKey: ["allCount"], queryFn: getAnalyticsCount });
  const candidates = useQuery({ queryKey: ["allCandidates"], queryFn: () => getCandidates({ limit: 10, page: 1, sort: false }) });
  const [state, setState] = useState<IState>({
    selectedTag: "Total Jobs",
    period: "weekly",
    analytics: {
      labels: [],
      data: []
    }
  });
  const getDayWiseAnalytics = useQuery({ queryKey: [state.selectedTag, state.period], queryFn: () => dayWiseAnalytics({ period: state.period, tag: state.selectedTag.replaceAll(" ", "").toLowerCase() }) });

  useEffect(() => {
    const labels: string[] = [];
    const data: number[] = [];
    if (getDayWiseAnalytics.data?.data.length) {
      getDayWiseAnalytics.data?.data.forEach(ele => {
        const date = formatDate(ele._id) === "Invalid Date" ? ele._id : formatDate(ele._id);
        console.log({ date });

        labels.push(date);
        data.push(ele.count);
      });
    }

    setState(prevState => (
      {
        ...prevState,
        analytics: {
          labels,
          data
        }
      }
    ));
  }, [getDayWiseAnalytics.data?.data]);

  const handleTagsChange = async (name: string) => {
    const selectedTag = name;
    setState(prevState => (
      {
        ...prevState,
        selectedTag
      }
    ));
  };

  const onChange = async (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setState(prevState => (
      {
        ...prevState,
        [name]: value
      }
    ));
  };

  const showAnalytics = () => {
    const data = {
      labels: [],
      datasets: [
        { data: [], backgroundColor: theme.palette.primary.light },
      ],
    };

    const selectedTagName = state.selectedTag;

    return {
      ...data, labels: state.analytics.labels, datasets: [
        { label: selectedTagName, data: state.analytics.data, backgroundColor: theme.palette.primary.light },
      ]
    };
  };

  if (candidates.data?.data.length) {
    rows = candidates.data?.data.map((candidate, index) => createRow(index, candidate));
  }

  return (
    <div className="dashboard">
      <Box className="tags" marginBottom="30px">
        <Typography className="head" variant="body1" marginBottom="10px" style={{ fontWeight: 700 }}>Overview</Typography>
        <Box className="overview-tabs">
          <Tag  
            name="Total Jobs"
            select={state.selectedTag}
            count={getAllCount.data?.data.totalJobs || 0}
            onClick={handleTagsChange}
          />
          <Tag
            name="Total Candidates"
            select={state.selectedTag}
            count={getAllCount.data?.data.totalCandidates || 0}
            onClick={handleTagsChange}
          />
          <Tag
            name="Total Conducted Interviews"
            select={state.selectedTag}
            count={getAllCount.data?.data.totalInterviewsConducted || 0}
            onClick={handleTagsChange}
          />
          <Tag
            name="Total Active Jobs"
            select={state.selectedTag}
            count={getAllCount.data?.data.totalOpeningJobs || 0}
            onClick={handleTagsChange}
          />
          <Tag
            name="Total Joined Candidates"
            select={state.selectedTag}
            count={getAllCount.data?.data.totalSelectedCandidates || 0}
            onClick={handleTagsChange}
          />
          <Tag
            name="Total Rejected Candidates"
            select={state.selectedTag}
            count={getAllCount.data?.data.totalRejectedCandidates || 0}
            onClick={handleTagsChange}
          />
          <Tag
            name="Not Contacted Candidates"
            select={state.selectedTag}
            count={getAllCount.data?.data.totalNotSelectedCandidates || 0}
            onClick={handleTagsChange}
            className="last-count-box"
          />
        </Box>
      </Box>


      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
            <Typography className="recent-applications m-0" variant="h6">Recent Applications</Typography>
            <div
              className="more-details"
              onClick={() => navigate("/candidates/intern?page=1")}
            >
              <Typography color={"primary"}>
              More Details
              </Typography>
            </div>
          </Box>
          <CustomTable
            columns={columns}
            rows={rows}
            height={340 + 20}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="center" marginBottom="15px" justifyContent="space-between">
            <Typography className="head" variant="body1" style={{ fontWeight: 600 }}>
              Analytics
            </Typography>
            <div className="date-picker position-relative">
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  size="small"
                  name="period"
                  value={state.period}
                  onChange={onChange}
                >
                  <MenuItem value="weekly">Current Week</MenuItem>
                  <MenuItem value="monthly">Current Month</MenuItem>
                  <MenuItem value="quarterly">Current Quarter</MenuItem>
                  <MenuItem value="yearly">Current Year</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>
          <BarGraph
            data={showAnalytics()}
            height={340}
          />
        </Grid>
      </Grid>


    </div>
  );
};

export default Dashboard;