import "./style.scss";
import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { ICollege } from "../../../../../interfaces";
import { formatDateTime } from "../../../../../utilities/helper";
import TimelineIcon from "@mui/icons-material/Timeline";
import dayjs from "dayjs";

interface props {
  college: ICollege | undefined;
}

const CollegeTimeline: FC<props> = ({ college }) => {
  const dateIsSame = (date1: Date | string, date2: Date | string) => dayjs(date1).startOf("day").isSame(dayjs(date2).startOf("day"));
  const theme = useTheme();
  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">


        <div id="timeline" style={{ height: "calc(100% - 50px)", overflow: "scroll" }}>
          {college?.activities?.length ?
            college?.activities.map((activity, index) => (
              <Box key={index} display="flex">
                <Box
                  className={dateIsSame(activity._id, new Date()) ? "text-white" : "grey-color"}
                  sx={{ backgroundColor: dateIsSame(activity._id, new Date()) ? theme.palette.primary.main : theme.palette.grey[200] }}
                >
                  <Typography
                    className="center h-100"
                    sx={{ width: "200px" }}
                    variant="h5"
                  >
                    {new Date(activity._id).getFullYear()}
                  </Typography>
                </Box>
                <div className="timelineBox w-100">
                  {[...activity.data].reverse().map((state, index) => (
                    <div
                      key={index}
                      className="timelineDescBox py-3 pl-5"
                    >
                      <Typography className="time" sx={{ width: "180px" }} variant="body1">
                        <Box className="w-100">
                          {formatDateTime(state.date)}
                        </Box>
                      </Typography>
                      <Typography className="desc" variant="body2">{state?.message}</Typography>
                    </div>
                  )
                  )}
                </div>
              </Box>))
            :
            <Box className="no-data center" height={"calc(100% - 30px)"}>
              <TimelineIcon fontSize='large' className='error-icon' />
              <Typography className="noData-title">
                No data to show!
              </Typography>
              <Typography className="noData-sub-title">
                Make an action to see timeline here
              </Typography>
            </Box>
          }
        </div>

        <Box paddingTop={3} className="center" justifyContent="start">
          <Box className="center" justifyContent="start">
            <Box
              className="identify-box"
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
            <Typography className="ml-2" variant="body1">
              Today
            </Typography>
          </Box>
          <Box className="center" justifyContent="start" marginLeft="30px">
            <div
              className="identify-box grey-color"
            />
            <Typography className="ml-2" variant="body1">
              Earlier Dates
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CollegeTimeline;