import { Box } from "@mui/material";
import { ChangeEvent } from "react";
import { useState, useEffect } from "react";
import { IPagination } from "../../interfaces/";
import { useQuery } from "@tanstack/react-query";
import { capitalize, createIndex, formatDateTime } from "../../utilities/helper";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/header";
import useDebounce from "../../hooks/useDebounce";
import CustomTable from "../../components/mui/table";
import useSnackbar from "../../hooks/useSnackbar";
import { IBroadcastGroup, IBroadcastGroupRow, IBroadcastGroupState } from "../../interfaces/broadcast-Group";
import { broadcastGroupService } from "../../services/broadcast Group";
import GetActions from "../../components/get-actions";
import useResource from "../../hooks/useResource";
import WarningDialog from "../../components/mui/warning-dialog";
import CustomTypography from "../../components/mui/max-length-limit";

const BroadcastGroup = () => {
  let rows: IBroadcastGroupRow[] = [];
  const { getbroadcastGroups, deletebroadcastGroup } = broadcastGroupService();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IBroadcastGroupState>({
    deleteWarning: false,
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    },
    keepFetching: 0,
    _broadcastGroupId: ""
  });

  const getbroadcastGroupsData = useQuery({
    queryKey: ["allBroadcastGroups", state.pagination.page, state.filters],
    queryFn: () => getbroadcastGroups({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getbroadcastGroupsData.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getbroadcastGroupsData.data.meta.page,
          totalPages: getbroadcastGroupsData.data.meta.totalPages,
          totalRecords: getbroadcastGroupsData.data.meta.totalRecords
        }
      }));
    }
  }, [getbroadcastGroupsData.data?.meta]);


  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const handleDelete = (_broadcastGroupId = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _broadcastGroupId
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deletebroadcastGroup({ _id: state._broadcastGroupId });
      snackbar(deleted.message, "info");
      handleDelete();
      getbroadcastGroupsData.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "group_name",
      label: "Group Name"
    },
    {
      id: "broadcast_count",
      label: "Template Count",
    },
    {
      id: "broadcast_leads",
      label: "Leads Count"
    },
    {
      id: "broadcast_createdAt",
      label: "Created At"
    },
    {
      id: "actions",
      label: "Actions"
    }
  ];

  const createRow = (index: number, broadcast: IBroadcastGroup, pagination: IPagination) => {
    const actions = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + broadcast._id }), disabled: resourceAllocate("broadcast-group.write") ? false : true },
        { name: "Delete", method: () => handleDelete(broadcast._id), disabled: resourceAllocate("broadcast-group.remove") ? false : true },
      ]}
    />;
    const name = resourceAllocate("broadcast.read") ? (
      <CustomTypography
        limit={120}
        label={capitalize(broadcast?.broadcastGroupName || "")}
        onClick={() => {navigate({ pathname: `/broadcast-group/${broadcast._id}/broadcast`, search: searchParams.toString()});}}
        color="primary"
      />
    ) : (
      <span>{capitalize(broadcast?.broadcastGroupName || "")}</span>
    );

    return {
      id: createIndex(pagination, index),
      group_name: name,
      broadcast_count: (broadcast?.broadcastCount || 0),
      broadcast_leads: (broadcast?.filter?.totalLeads || 0),
      broadcast_createdAt: formatDateTime(broadcast?.createdAt),
      actions
    };
  };

  if (getbroadcastGroupsData.data?.data.length) {
    rows = getbroadcastGroupsData.data?.data.map((broadcast, i) => createRow(i, broadcast, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by group name"
        onSearch={onSearch}
        btnText="BROADCAST GROUP"
        onBtnClick={resourceAllocate("broadcast-group.write") ? () => navigate("new") : undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 191.3px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Broadcast Group"
        description="Are you sure you want to delete this broadcast group?"
      />
      <Outlet context={{ reFetch: getbroadcastGroupsData.refetch }} />
    </>
  );
};
export default BroadcastGroup;

