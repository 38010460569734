export const LOAD_JOB = "LOAD_JOB";
export const LOAD_CPD_DRIVE = "LOAD_CPD_DRIVE";

// Configuration 
export const LOAD_USER = "LOAD_USER";
export const LOAD_ROLE = "LOAD_ROLE";
export const LOAD_TEAM = "LOAD_TEAM";
export const LOAD_ENGAGEMENT_TYPE = "LOAD_ENGAGEMENT_TYPE";
export const LOAD_JOB_POSITION = "LOAD_JOB_POSITION";
export const LOAD_JOB_TITLE = "LOAD_JOB_TITLE";
export const LOAD_STATUS = "LOAD_STATUS";
export const LOAD_TEMPLATE = "LOAD_TEMPLATE";
export const LOAD_COLLEGE = "LOAD_COLLEGE";

//pms-config

export const LOAD_CMS_PRODUCT = "LOAD_CMS_PRODUCT";
export const LOAD_CMS_QUARTER = "LOAD_CMS_QUARTER";
export const LOAD_CMS_CATEGORY = "LOAD_CMS_CATEGORY";
export const LOAD_CMS_SUBCATEGORY = "LOAD_CMS_SUBCATEGORY";

// theme-config

export const THEME_CONFIGURATION = "THEME_CONFIGURATION";
