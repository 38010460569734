import "../style.scss";
import { ChangeEvent, FC, useState } from "react";
import { Box, Button, Grid, InputLabel, Link, MenuItem, TextField, useTheme } from "@mui/material";
import GetActions from "../../../../components/get-actions";
import { IVendorEdit } from "../../../../interfaces/vendor";
import useSnackbar from "../../../../hooks/useSnackbar";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../interfaces/shared/response";
import { IColumn } from "../../../../interfaces/shared";
import { capitalize, formatDate } from "../../../../utilities/helper";
import CustomTable from "../../../../components/mui/table";
import WarningDialog from "../../../../components/mui/warning-dialog";
import ManageVendorContractDetail from "../contract-details/manage";
import { VendorContractService } from "../../../../services/vendor/contract-detail";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../components/mui/select";
import HttpService from "../../../../services/http";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomLabel from "../../../../components/mui/custom-label";
import { IContract, IContractDetail, IContractRow, IContractState } from "../../../../interfaces/vendor/contract-details";
import { VendorCategoryService } from "../../../../services/vendor/vendor-category";
import { vendorAddContractValidation } from "../../../../validations/vendor/manage-vendor/contract-detail";
import { joiResolver } from "@hookform/resolvers/joi";

interface props {
  vendor: IVendorEdit | undefined;
  isDisable?: boolean;
  activeAction: boolean;
  setIsDisable?: (e: boolean) => void;
  onClose?: () => void;
  reFetch?: () => void
  setActiveAction: (e: boolean) => void;
}

const ContractDetail: FC<props> = ({ vendor, activeAction, setActiveAction }) => {
  const theme = useTheme(); 
  const { httpFormRequest } = HttpService();
  const [contractId, setContractId] = useState("");
  const { snackbar } = useSnackbar();
  const { addContract, deleteContract, getContracts } = VendorContractService();
  const [state, setState] = useState<IContractState>({
    list: [],
    deleteWarning: false,
    manageContract: {
      isOpen: false,
      contract: {
        name: "",
        _category: {
          _id: "",
          name: ""
        },
        link: "",
        startDate: "",
        endDate: "",
        _id: "",
      }
    },
    _contractDetail: "",
    deleteIndex: -1
  });

  const { control, handleSubmit, reset, trigger, setValue, getValues, formState: { errors } } = useForm<IContractDetail>({
    resolver: joiResolver(vendorAddContractValidation),
    defaultValues: {
      name: "",
      link: "",
      startDate: "",
      endDate: ""
    }
  });

  const contracts = useQuery({
    queryKey: ["allContracts"],
    queryFn: () =>
      getContracts({
        _vendor: vendor?._id
      }),
    enabled: !!vendor?._id
  });

  let rows: IContractRow[] = [];
  const handleDelete = (_id?: string) => setState(prevState => ({
    ...prevState,
    deleteWarning: !prevState.deleteWarning,
    _contractDetail: _id ? _id : ""
  }));

  const onDelete = async () => {
    try {
      const id = {
        _id: state._contractDetail,
      };
      const deletedCandidate = await deleteContract(id);
      snackbar(deletedCandidate.message, "info");
      handleDelete();
      contracts.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleDelete();
      console.log({ "Error in delete contract": error });
    }
  };

  const handleEdit = (contract?: IContractDetail) => setState(prevState => {
    if (contract?._id) {
      setContractId(contract._id);
    }
    const newState = {
      ...prevState,
      manageContract: {
        ...prevState.manageContract,
        isOpen: !prevState.manageContract.isOpen,
      },
      _contractDetail: contract ? contract._id : "",
    } as IContractState;

    return newState;
  });

  const { getVendorPartialCategory } = VendorCategoryService();

  const categories = useQuery({
    queryKey: ["docCategories"],
    queryFn: () =>
      getVendorPartialCategory({
        type: "DOCUMENT",
      }),
    enabled: activeAction
  });

  const onSubmit = async (data: IContractDetail) => {
    try {
      const contactDetail = await addContract({
        ...data,
        _vendor: vendor?._id,
      });
      reset();
      setActiveAction(false);
      snackbar(contactDetail?.message, "info");
      contracts.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "warning");
      console.log("error in contract detail", error);
    }
  };

  const fields: IContract[] = [
    {
      type: "input",
      name: "name",
      label: "Name",
      placeholder: "Type name here",
      required: true
    },
    {
      type: "select",
      required: true,
      displayFieldKey: "name",
      storeFieldKey: "_id",
      name: "_category",
      label: "Category",
      placeholder: "Select category",
      children: categories?.data?.data && categories?.data?.data.map((category) => <MenuItem key={category._id} value={category._id} >{capitalize(category.name)}</MenuItem>)
    },
    {
      label: "Start Date",
      name: "startDate",
      type: "date",
      required: true
    },
    {
      label: "End Date",
      name: "endDate",
      type: "date",
    },
    {
      label: "Document",
      name: "link",
      type: "doc",
      required: true
    }
  ];

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "category",
      label: "Category"
    },
    {
      id: "document",
      label: "Document"
    },
    {
      id: "startDate",
      label: "Start Date"
    },
    {
      id: "endDate",
      label: "End Date"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const uploaded = await httpFormRequest<{ data: string }>(
        e.target.files,
        e.target.files ? e.target.files[0].name : "",
        ["doc", "docx", "pdf", "png", "jpeg", "jpg"],
        10
      );
      setValue("link", uploaded.data.split("uploads")[1]);
    } catch (error) {
      console.log("error in document detail upload", error);
    }

  };

  const createRow = (index: number, contractDetail: IContractDetail) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => handleEdit(contractDetail) },
        { name: "Delete", method: () => handleDelete(contractDetail._id) },
      ]}
    />;

    const doc = <Link href={process.env.REACT_APP_S3_BASE_URL + contractDetail.link} target="_blank" underline="none" color={theme.palette.primary.main}>
      {contractDetail.link}
    </Link>;

    return {
      id: index + 1,
      name: capitalize(contractDetail.name),
      category: contractDetail?._category.name,
      document: doc,
      startDate: contractDetail?.startDate && formatDate(contractDetail?.startDate),
      endDate: contractDetail?.endDate && formatDate(contractDetail?.endDate),
      action
    };
  };

  if (contracts?.data?.data?.length) {
    rows = contracts?.data?.data.map((contract, i) => createRow(i, contract));
  }

  const selectDate = (value: string | number | Date | dayjs.Dayjs | null | undefined, name: string) => {
    const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
    const keyExist = name === "startDate" || name === "endDate";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        {/* Add Data  */}
        {activeAction &&
          <Box marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
              <Grid container spacing={4}>
                {
                  fields.map(field => {
                    if (field.type === "input") {
                      return (<Grid key={field.label} item xs={4}>
                        <Controller
                          control={control}
                          name={field.name}
                          render={(prop) => <TextField
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            className="disable-text"
                            variant={"outlined"}
                            size={"small"}
                            placeholder={field.placeholder}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message}
                            {...prop.field}
                          />}
                        />
                      </Grid>
                      );
                    } else if (field.type === "select") {
                      return (<Grid key={field.label} item xs={4} >
                        <Select
                          control={control}
                          className="disable-text"
                          name={field.name}
                          label={<CustomLabel label={field.label} required={field?.required} />}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                        >
                          {field.children}
                        </Select>
                      </Grid>
                      );
                    } else if (field.type === "date" && field.name !== "_category") {
                      return (<Grid key={field.label} item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            onChange={(e) => selectDate(e, field.name)}
                            value={dayjs(getValues(field.name))}
                            slotProps={{
                              textField: {
                                error: errors[field.name] ? true : false,
                                helperText: errors[field.name]?.message
                              }
                            }}
                            format="LL"
                          />
                        </LocalizationProvider>
                      </Grid>
                      );
                    } else {
                      const hasValue = getValues(field.name);
                      return (
                        <Grid key={field.label} item xs={4} >
                          <div>
                            <div style={{ color: "rgb(118, 118, 118)", marginTop: "-17px", marginBottom: "3px" }}>
                              {hasValue &&
                                <label>{field.label}</label>
                              }
                            </div>
                            <Grid container spacing={2}>
                              {hasValue && (
                                <Grid item xs>
                                  <Link href={!getValues(field.name)?.toString().includes("https://") ? String(process.env.REACT_APP_S3_BASE_URL) + getValues(field.name)?.toString() : getValues(field.name)?.toString()} target="_blank" underline="none" color="inherit">
                                    <Button variant="outlined" fullWidth>
                                      Preview
                                    </Button>
                                  </Link>
                                </Grid>
                              )}
                              {!hasValue && (
                                <Grid item xs>
                                  <InputLabel id={`upload-${field.name}`} >
                                    <Button component="label" variant="outlined" fullWidth>
                                      Upload {field.label}*
                                      <input hidden type="file" id={`upload-${field.name}`} onChange={e => uploadFile(e)} accept="application/pdf, image/png, image/jpeg, .doc, .docx" />
                                    </Button>
                                  </InputLabel>
                                  {errors[field.name] && <span style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                                </Grid>
                              )}
                              {hasValue && (
                                <Grid item xs>
                                  <Button onClick={() => {
                                    setValue(field.name, "");
                                    trigger(field.name);
                                  }} variant="outlined" color="error" fullWidth>
                                    Delete
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        </Grid>
                      );
                    }
                  })
                }
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    type="submit"
                  >
                    Add Contract Details
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        }

        {/* Show Data  */}
        <CustomTable
          columns={columns}
          rows={rows}
          height={activeAction ? "calc(100% - 145px)" : "calc(100% - 13px)"}
          width="calc(100% - 2px)"
        />

        {/* Delete Data  */}
        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => handleDelete()}
          onConfirm={onDelete}
          title="Delete Contract Detail"
          description="Are you sure you want to delete this contract detail"
        />

        {/* Manage Data  */}
        <ManageVendorContractDetail
          contract={contractId}
          isOpen={state.manageContract.isOpen}
          onClose={handleEdit}
          refetch={contracts.refetch}
        />
      </Box>
    </Box>
  );
};

export default ContractDetail;