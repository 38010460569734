export const routePermission = [
    //Dashboard
    {
        path: "/home",
        permissions: ["common.read"]
    },
    {
        path: "/home",
        permissions: ["cms-home"]
    },
    {
        path: "/dashboard",
        permissions: ["dashboard"]
    },

    {
        path: "/content/products",
        permissions: ["cms-product-title"]
    },
    {
        path: "/content/category",
        permissions: ["cms-category"]
    },
    {
        path: "/content/sub-category",
        permissions: ["cms-sub-category"]
    },
    {
        path: "/content/keywords",
        permissions: ["cms-keyword"]
    },
    {
        path: "/content/parts-of-funnel",
        permissions: ["cms-funnel-parts"]
    },
    {
        path: "/content/type-of-content",
        permissions: ["cms-content-types"]
    },
    {
        path: "/content/score-data",
        permissions: ["cms-score-data"]
    },
    {
        path: "/content/goals",
        permissions: ["cms-goal"]
    },
    {
        path: "/content/product-mapping",
        permissions: ["cms-product-mapping"]
    },
    {
        path: "/content/author",
        permissions: ["cms-author"]
    },
    {
        path: "/content/writer-report-roles",
        permissions: ["cms-writer-report-roles"]
    },
    {
        path: "/planner",
        permissions: ["cms-quarter"]
    },

    //Candidates
    {
        path: "/candidates",
        permissions: ["lead"]
    },
    {
        path: "/blog",
        permissions: ["cms-blog"]
    },
    //Cpd
    {
        path: "/cpd/dashboard",
        permissions: ["cpd-dashboard"]
    },
    {
        path: "/cpd/drives",
        permissions: ["cpd-drives"]
    },
    {
        path: "/cpd/college",
        permissions: ["college"]
    },
    //Interview
    {
        path: "/assessments/interview",
        permissions: ["interview"]
    },
    {
        path: "/assessments/review",
        permissions: ["review"]
    },

    //Careers
    {
        path: "/careers/jobs",
        permissions: ["job"]
    },
    {
        path: "/careers/employee-review",
        permissions: ["employee-review"]
    },
    {
        path: "/careers/college-images",
        permissions: ["college-image"]
    },
    {
        path: "/careers/office-employee-images",
        permissions: ["employee-image"]
    },
    {
        path: "/careers/faqs",
        permissions: ["faq"]
    },
    {
        path: "/careers/highlight",
        permissions: ["careers-highlight.read"]
    },

    //Broadcast
    {
        path: "/broadcast-group",
        permissions: ["broadcast-group"]
    },

    //Offered
    {
        path: "/offered",
        permissions: ["offer"]
    },

    //Report
    {
        path: "/reports/overview",
        permissions: ["report-analytics"]
    },
    {
        path: "/reports/jobs",
        permissions: ["report-analytics"]
    },
    {
        path: "/reports/candidate",
        permissions: ["report-analytics"]
    },
    {
        path: "/reports/team-performance",
        permissions: ["report-analytics"]
    },
    {
        path: "/reports/cpd",
        permissions: ["report-analytics"]
    },
    {
        path: "/reports/kpi",
        permissions: ["kpi"]
    },
    {
        path: "/reports/cms",
        permissions: ["cms-blog-analytics"]
    },
    //Trash
    {
        path: "/trash/candidate-trash",
        permissions: ["leads-trash"]
    },
    {
        path: "/trash/blog-trash",
        permissions: ["blogs-trash"]
    },

    //Confugurations
    {
        path: "/configurations/user",
        permissions: ["user"]
    },
    {
        path: "/configurations/roles",
        permissions: ["user-role"]
    },
    {
        path: "/configurations/resource-request-roles",
        permissions: ["resource-request-role"]
    },   
    {
        path: "/configurations/assessments-instruction", 
        permissions: ["assessment-instruction"]
    },
    {
        path: "/configurations/departments",
        permissions: ["department"]
    },
    {
        path: "/configurations/engagement-type",
        permissions: ["job-type"]
    },
    {
        path: "/configurations/job-title",
        permissions: ["job-title"]
    },
    {
        path: "/configurations/template",
        permissions: ["template"]
    },
    {
        path: "/configurations/status",
        permissions: ["status"]
    },
    {
        path: "/configurations/integration",
        permissions: ["google-integration"]
    },
    {
        path: "/configurations/parameters",
        permissions: ["interview-parameter"]
    },
    {
        path: "/configurations/review-parameters",
        permissions: ["review-parameter"]
    },
    {
        path: "/configurations/onboard-user",
        permissions: ["onboard-user"]
    },
    {
        path: "/configurations/daily-report-roles",
        permissions: ["daily-report-roles"]
    },
    //TODO - Update coder-byte permission
    {
        path: "/configurations/coder-byte",
        permissions: ["coderbyte-links"]
    },
    {
        path: "/configurations/automation",
        permissions: ["automation"]
    },
    {
        path: "/configurations/automation/list",
        permissions: ["automation"]
    },
    {
        path: "/configurations/tests",
        permissions: ["test"]
    },
    {
        path: "/configurations/question-tags",
        permissions: ["technical-test-tag"]
    },
    {
        path: "/configurations/question-languages",
        permissions: ["technical-question-languages"]
    },
    {
        path: "/configurations/technical-questions",
        permissions: ["technical-question"]
    },
    {
        path: "/configurations/assessments",
        permissions: ["assessment"]
    },
    {
        path: "/configurations/business-unit",
        permissions: ["business-unit"]
    },
    {
        path: "/configurations/designation",
        permissions: ["job-position"]
    },
    {
        path: "/configurations/tests/test-info/manage/categories",
        permissions: ["test-category"]
    },
    {
        path: "/configurations/tests/test-info/manage/questions",
        permissions: ["question"]
    },
    {
        path: "/configurations/tests/test-info/manage/response",
        permissions: ["response"]
    },
    {
        path: "/settings",
        permissions: ["common.read"]
    },
    {
        path: "/skill-matrix",
        permissions: ["skill-matrix"]
    },
    {
        path: "/requested-skill",
        permissions: ["skill-matrix-request"]
    },

    //Directory management
    {
        path: "/directory",
        permissions: ["vendor"]
    },

    //Blogs Insights
    {
        path: "/blogs-analytics",
        permissions: ["cms-blog-insights"]      
    },
    {
        path: "/resource-requested",
        permissions: ["resource-request"]
    },

    //Organisation
    {
        path: "/users",
        permissions: ["user"]
    },
    {
        path: "/organisation-tree",
        permissions: ["organisation-tree"]
    },
    {
        path: "/organisation-configuration",
        permissions: ["organisation-configuration"]
    }
];