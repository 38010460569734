import "./style.scss";
import { Box, Button, capitalize, Tab, Tabs } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams, Outlet } from "react-router-dom";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { VendorsService } from "../../../services/vendor";
import ContractDetail from "./contract-details";
import ContactPerson from "./contact-person";
import AddIcon from "@mui/icons-material/Add";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel, a11yProps } from "../../../components/shared/mui-tabs";
import BasicDetail from "./basic-details";
import Notes from "./notes";
import PaymentDetails from "./payment-details";
import TransactionDetails from "./transaction";
import useResource from "../../../hooks/useResource";

interface SideButtonProps {
  index: number;
  setActiveAction: (e: boolean) => void;
}

const SideButton: FC<SideButtonProps> = ({ index, setActiveAction }) => {
  let label = "";

  if (index === 1) {
    label = "Add Contact Person";
  } else if (index === 2) {
    label = "Add Contract Details";
  } else if (index === 3) {
    label = "Add notes";
  } else if (index === 4) {
    label = "Add Payment Mode";
  }else if (index === 5) {
    label = "Add Transaction";
  }else {
    return null;
  }

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<AddIcon />}
      onClick={() => setActiveAction(true)}
    >
      {label}
    </Button>
  );
};

const redirectionLinks = ["basic-detail", "contact-person", "contract-details", "notes", "payment-details", "transactions"];
interface outletProps {
  reFetch: () => void
}

const ManageVendor = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { id } = useParams();
  const [activeAction, setActiveAction] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const { getVendor } = VendorsService();
  const [isDisable, setIsDisable] = useState(true);
  const [searchParam, setSearchParam] = useSearchParams();
  const vendor = useQuery({
    queryKey: ["vendor"],
    queryFn: () => getVendor({ _id: id })
  });
  
  useEffect(() => {
    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
    setValue(redirectionLinks.indexOf(tab));
  }, [searchParam]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", redirectionLinks[newValue]);
    setSearchParam(searchParam);
    setActiveAction(false);
  };

  const onClose = () => {
    searchParam.delete("type");
    navigate({ pathname: "/directory/vendors", search: searchParam.toString() });
  };

  const name = vendor.data?.data.vendorName ? capitalize(vendor.data?.data.vendorName) : "Vendor Detail";

  return <div>
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      open={id ? true : false}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          {name} {resourceAllocate("vendor.write") && <IconButton sx={{ visibility: value === 0 ? "visible" : "hidden" }} className="ml-2" color="primary" onClick={() => setIsDisable(false)} ><EditIcon /></IconButton>}
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: "65vh" }}>
        <Box width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Box>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />
                <Tab className="tabs-space" label="Contact Persons" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Contract Details" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Notes" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Payment Modes" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Transactions" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {!activeAction && resourceAllocate("vendor.write") && (
              <SideButton index={value} setActiveAction={setActiveAction} />
            )}
          </Box>

          {/* Basic detail  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={0}>
            <BasicDetail
              vendor={vendor.data?.data}  
              isDisable={isDisable}
              setIsDisable={setIsDisable}
            />
          </TabPanel>

          {/* Contact person  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={1}>
            <ContactPerson
                  vendor={vendor.data?.data}
                  activeAction={activeAction}
                  setActiveAction={setActiveAction}
            />
          </TabPanel>

          {/* Contract Details  */}
          <TabPanel value={value} index={2}>
            <ContractDetail
                vendor={vendor.data?.data}
                isDisable={isDisable}
                activeAction={activeAction}
                setIsDisable={setIsDisable}
                onClose={onClose}
                reFetch={outlet.reFetch}
                setActiveAction={setActiveAction}
            />
          </TabPanel>

          {/* Notes  */}
          <TabPanel value={value} index={3}>
            <Notes
              vendor={vendor.data?.data}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
            />
          </TabPanel>

          {/* Payment Details */}
          <TabPanel value={value} index={4}>
            <PaymentDetails
              vendor={vendor.data?.data}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
            />
          </TabPanel>

          {/* Transaction Details */}
          <TabPanel value={value} index={5}>
            <TransactionDetails
              vendor={vendor.data?.data}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
            />
          </TabPanel>

        </Box>
      </DialogContent>
    </BootstrapDialog>

    <Outlet context={{...outlet}}/>
  </div>;
};

export default ManageVendor;
