import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {  IErrorResponse, IField } from "../../../../../interfaces";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { blogScheduleValidation } from "../../../../../validations";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import useSnackbar from "../../../../../hooks/useSnackbar";
import CustomDialog from "../../../../../components/mui/dialog";
import { Box, Grid } from "@mui/material";
import { blogService } from "../../../../../services/blog";

interface outletProps {
    reFetch: () => void
}

interface IScheduleBlogField extends IField {
    name: "scheduledDate";
}

export interface IScheduleBlog {
    scheduledDate: string | undefined;
}

const ScheduleBlog = () => {
    const { id } = useParams();

    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { scheduleBlog, getBlog } = blogService();
    const { handleSubmit, getValues, setValue, trigger, formState: { errors } } = useForm<IScheduleBlog>({
        resolver: joiResolver(blogScheduleValidation),
        defaultValues: {
            scheduledDate: "",
        }
    });

    const getBlogDetail = useQuery({
        queryKey: ["blog-details"],
        queryFn: () => getBlog({
            _id: id
        })
    });

    useEffect(() => {
        if (getBlogDetail.data?.data) {
            setValue("scheduledDate", String(getBlogDetail.data?.data.scheduledDate) || "");
            trigger("scheduledDate");
        }
    }, [getBlogDetail.data]);

    const onSubmit = async (data: IScheduleBlog) => {
        try {
            if (id) {
                const selectedTime = dayjs(data.scheduledDate);
                const currentTime = dayjs();
                if (selectedTime.isBefore(currentTime, "minute")) {
                    snackbar("Blog cannot be in the past. Please select a current or future time", "warning");
                    return;
                }

                const payload = { ...data, blogId: id };
                const reschedule = await scheduleBlog(payload);
                snackbar(reschedule.message, "info");
                onClose();
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    // eslint-disable-next-line
    const selectDate = (value: any) => {
        const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
        setValue("scheduledDate", date);
        trigger("scheduledDate");
    };
    const onClose = () => {
        navigate({
            pathname: `/blog/manage/${id}`,
            search: searchParam.toString()
        });
    };

    const fields: IScheduleBlogField[] = [
        {
            label: "Date and Time*",
            name: "scheduledDate",
            type: "date",
        },
    ];

    return (
        <>
            <Box>
                <CustomDialog
                    title={"Schedule Blog"}
                    size="xs"
                    isOpen={id ? true : false}
                    onClose={onClose}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "date") {
                                    return (
                                        <Grid key={field.label} item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDateTimePicker
                                                    label={field.label}
                                                    onChange={selectDate}
                                                    slotProps={{
                                                        textField: {
                                                            error: errors[field.name] ? true : false,
                                                            helperText: errors[field.name]?.message
                                                        }
                                                    }}
                                                    // value={dateValue}
                                                    value={dayjs(getValues(field.name) ? getValues(field.name) : null)}
                                                    format="LLL"
                                                    shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), "day")}
                                                />
                                            </LocalizationProvider>

                                        </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                </CustomDialog>
            </Box>
        </>
    );

};

export default ScheduleBlog;